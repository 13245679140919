.profile-container {
  width: 100%;
  height: 100vh;
  & .profile-heading {
    margin: 3rem auto;
    text-align: center;
    & h1 {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 3rem;
    }
  }
  & .consumer-option-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & input {
      margin: 0 5px;
    }
    & .submit-btn {
      width: fit-content;
      font-size: 1rem;
      border-radius: 5px;
      outline: none;
      border: none;
      margin: 50px auto;
      padding: 10px 15px;
    }
  }
}
