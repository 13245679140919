.forgot-password-container {
  width: 100%;
  height: 100vh;
  & .forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 auto;

    & .forgot-password-desc {
      margin: 3rem 0;
    }
    & input {
      width: 100%;
      padding: 10px;
      font-size: 1rem;
      border: 1px solid $color-border;
      border-radius: 5px;
      &[type="number"] {
        -moz-appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    & .error-msg {
      color: $color-error-msg;
      font-size: 0.8rem;
      padding: 0.5rem;
    }
    & button {
      width: fit-content;
      min-width: 200px;
      font-size: 1rem;
      border-radius: 5px;
      outline: none;
      border: none;
      margin: 20px auto;
      padding: 10px 15px;
    }
  }
}

.reset-password-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .reset-password-desc {
    margin: 3rem auto;
    text-align: center;
  }
  & .reset-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;

    & input {
      width: 100%;
      padding: 10px;
      font-size: 1rem;
      border: 1px solid $color-border;
      border-radius: 5px;
      margin: 10px 0;
    }
    & .error-msg {
      color: $color-error-msg;
      font-size: 0.8rem;
    }
  }
  & .password-constraints-container {
    margin: 10px auto;
    & ul {
      display: flex;
      flex-wrap: wrap;
      & li {
        flex-basis: 50%;
        flex-grow: 1;
        list-style-type: "- ";
        font-size: 0.8rem;
      }
    }
  }
  & .btn-confirm {
    width: fit-content;
    min-width: 200px;
    font-size: 1rem;
    border-radius: 5px;
    outline: none;
    border: none;
    margin: 10px auto;
    padding: 5px;
  }
}
.otp-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);

  & .otp-modal {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    & .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & p {
        margin: 0;
      }
      & button {
        border: none;
        outline: none;
        background-color: transparent;
      }
    }
    & .modal-body {
      padding: 0;
      & .otp-input-container {
        display: flex;
        justify-content: space-around;
        align-items: space-evenly;
        margin: 10px auto;
        & input {
          width: 30px;
          height: 30px;
          border: none;
          outline: none;
          border-bottom: 1px solid $color-border;
          text-align: center;
          &[type="number"] {
            -moz-appearance: textfield;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
      & .error-msg {
        display: flex;
        justify-content: center;
        color: $color-error-msg;
      }
    }
    & .btn-container {
      & .btn-validate {
        width: fit-content;
        min-width: 200px;
        font-size: 1rem;
        border-radius: 5px;
        outline: none;
        border: none;
        margin: 10px auto;
        padding: 5px;
      }
      & .resend-otp {
        margin: 10px auto;
        text-decoration: none;
      }
    }
  }
  & .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .resend-otp {
    border: none;
    background: none;
    text-decoration: underline;
    color: $color-link;
  }
  & .otp-timer {
    text-align: right;
    margin-right: 1rem;
  }
  & .disable-link {
    color: $color-highlight;
  }
  & .error-msg {
    display: flex;
    justify-content: center;
    color: $color-error-msg;
    text-align: center;
  }
}
