.login-page {
  width: 100%;
  height: 100vh;
  & .login-page-heading {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 3rem;
    margin: 3rem auto;
    text-align: center;
    text-transform: uppercase;
  }
  & #loginScreensetContainer {
    & .gigya-login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      & .gigya-layout-row {
        width: 70%;
        padding: 10px 20px;
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          display: none;
        }
      }
      & .gigya-input-text,
      & .gigya-input-password {
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        border: 1px solid $color-border;
        border-radius: 5px;
      }
      & .gigya-input-password::-ms-reveal,
      .gigya-input-password::-ms-clear {
        display: none;
      }
      & .gigya-input-submit {
        width: 90%;
        font-size: 1rem;
        border-radius: 5px;
        outline: none;
        border: none;
        margin: 10px auto;
        padding: 5px;
      }
    }
  }
}
