* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.loader-container {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: 2px;
  & .loader {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.popup-container {
  position: fixed;
  bottom: 10%;
}
.legal-docs-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  & .legal-docs-desc {
    text-align: center;
    margin: 3rem auto;
  }
  & .links-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    & a {
      text-transform: uppercase;
    }
  }
}
.navbar-brand {
  font-weight: 700;
}
